import React, { useEffect, useState } from "react"

const CookiesBanner = () => {
  const [agreed, setAgreed] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem(process.env.SITE_ID)) {
      setAgreed(true)
    }
  }, [])

  const handleClick = () => {
    localStorage.setItem(process.env.SITE_ID, "true")
    setAgreed(false)
  }

  if (agreed) {
    return (
      <div className="cookies_banner w-full fixed bottom-0 px-7 py-4 flex justify-between items-center bg-neutral-300 z-50">
        <p>
          Webbplatsen använder cookies för att bla ge dig en bättre
          användarupplevelse.{" "}
          <a
            href="https://uploads.staticjw.com/ad/addictive/cookiepolicy.pdf"
            target="_blank"
          >
            Cookiepolicy
          </a>
        </p>
        <button className="button" onClick={handleClick}>
          OK
        </button>
      </div>
    )
  }
  return <></>
}

export default CookiesBanner
