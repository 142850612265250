import * as React from "react"

import Navigation from "./navigation"
import { HeroPicker } from "./hero/heroPicker"
import Footer from "./Footer/Footer"
import Content from "./Content/Content"
import SidebarForm from "./Form/SidebarForm"
import CTABlock from "./CTABlock/CTABlock"
import CookiesBanner from "./CookiesBanner"
import { PageContext } from "../context/pageProvider"

const MAX_MOBILE_SIZE = 720

const StandardPage = () => {
  const [isMobileContent, setIMobileContent] = React.useState()

  const pageContext = React.useContext(PageContext)
  const {
    hero,
    cta,
    content,
    contentData,
    additionalContents,
    additionalMobileContents,
    mobileContentData,
    _type,
  } = pageContext.page

  const { variantContentData, variantAdditionalContents } =
    React.useMemo(() => {
      let variantContentData = contentData
      let variantAdditionalContents = additionalContents

      if (isMobileContent && _type === "startpage") {
        if (mobileContentData) {
          const tempDiv = document.createElement("div")
          tempDiv.innerHTML = mobileContentData

          if (tempDiv.textContent) {
            variantContentData = mobileContentData
          }
        }

        if (additionalMobileContents?.length) {
          variantAdditionalContents = [...additionalMobileContents]
        }
      }

      return { variantContentData, variantAdditionalContents }
    }, [pageContext.page, isMobileContent])

  React.useEffect(() => {
    const handleResize = () => {
      setIMobileContent(MAX_MOBILE_SIZE > window?.innerWidth)
    }

    handleResize()

    window?.addEventListener("resize", handleResize)

    return () => {
      window?.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <CookiesBanner />
      <Navigation />
      <div
        className={`${
          pageContext.meta.settings?.callInHeader ? "pt-28" : "pt-16"
        } sm:pt-10 lg:pt-8 xl:pt-4`}
      >
        <HeroPicker hero={hero} cta={cta} />
      </div>
      <div className="content_sidebar elva flex justify-between lg:px-4">
        <Content
          typePage={_type}
          content={content}
          contentData={variantContentData}
          additionalContents={variantAdditionalContents}
        />
        <div className={_type === "startpage" ? "hiddenMobile" : ""}>
          <SidebarForm />
        </div>
      </div>
      <CTABlock />
      <Footer />
    </>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default StandardPage
