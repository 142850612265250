import React, { useEffect, useState } from "react"

const FormField = ({ data, onChange, isErrors, clearInput }) => {
  const { title, fieldType, required } = data
  const [clearValue, setClearValue] = useState("")
  const [checkboxValues, setCheckboxValues] = useState({})
  const [radioValue, setRadioValue] = useState("")
  useEffect(() => {
    setClearValue("")
  }, [clearInput])

  useEffect(() => {
    if (fieldType === "Checkbox" && data.values?.length > 0) {
      const initialValues = {}
      data.values.forEach(option => {
        initialValues[option] = false
      })
      setCheckboxValues(initialValues)
      if (fieldType === "Radio" && data.values?.length > 0) {
        setRadioValue(data.values[0])
      }
      if (fieldType === "Select" && data.values?.length > 0) {
        const defaultSelectValue = data.values[0]
        onChange(defaultSelectValue, data)
      }
    }
  }, [])

  useEffect(() => {
    if (fieldType === "Checkbox") {
      onChange(checkboxValues, data)
    }
    if (fieldType === "Radio") {
      onChange(radioValue, data)
    }
  }, [checkboxValues, radioValue])

  const handleCheckboxChange = option => {
    setCheckboxValues(prevValues => {
      const newValues = { ...prevValues, [option]: !prevValues[option] }
      return newValues
    })
  }

  if (fieldType === "Select") {
    if (data.values?.length > 0) {
      return (
        <label className="text-sm flex flex-col">
          {title || "Select"}:{required && " *"}
          <select
            className="mb-3.5 w-full input py-1 rounded"
            onChange={e => {
              onChange(e.target.value, data)
            }}
          >
            {data.values.map(option => (
              <option value={option}>{option}</option>
            ))}
          </select>
        </label>
      )
    }
    return null
  }

  if (fieldType === "Checkbox") {
    if (data.values?.length > 0) {
      return (
        <fieldset className="mb-3.5">
          <legend className="text-sm">
            {title || "Checkbox"}:{required && " *"}
          </legend>
          {data.values.map(option => {
            return (
              <div className="flex gap-2.5 items-center">
                <input
                  checked={checkboxValues[option] || false}
                  onChange={() => handleCheckboxChange(option)}
                  type="checkbox"
                  id={option}
                  name={option}
                />
                <label for={option}>{option}</label>
              </div>
            )
          })}
        </fieldset>
      )
    }
    return null
  }

  if (fieldType === "Radio") {
    if (data.values?.length > 0) {
      return (
        <fieldset className="mb-3.5">
          <legend>
            {title || "Radio"}:{required && " *"}
          </legend>
          {data.values.map(option => (
            <div key={option} className="flex gap-2.5 items-center">
              <input
                checked={radioValue === option}
                onChange={() => setRadioValue(option)}
                type="radio"
                id={option}
                name={title}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </fieldset>
      )
    }
    return null
  }

  return (
    <label className="text-sm">
      {title}:{required && " *"}
      {fieldType === "textarea" ? (
        <textarea
          onChange={e => {
            setClearValue(e.target.value)
            onChange(e.target.value, data)
          }}
          className={`mb-3.5 w-full rounded h-28 ${isErrors && "inputError"}`}
          value={clearValue}
        ></textarea>
      ) : (
        <input
          onKeyDown={e => {
            if (e.keyCode === 13) {
              e.preventDefault()
            }
          }}
          onChange={e => {
            setClearValue(e.target.value)
            onChange(e.target.value, data)
          }}
          className={`mb-3.5 input rounded w-full h-8 ${
            isErrors && "inputError"
          }`}
          type={fieldType}
          value={clearValue}
          pattern={fieldType === "tel" ? "[0-9|+]{9,13}" : ""}
        ></input>
      )}
    </label>
  )
}
export default FormField
