import IMAGE_CONTENT_TYPES from "../constants/imageContentTypes"

export const decodeHtml = html => {
  var txt = document.createElement("textarea")
  txt.innerHTML = html
  return txt.value
}

const createFigure = imageNode => {
  const figure = document.createElement("figure")
  const figcaption = document.createElement("figcaption")

  const text = decodeHtml(imageNode.getAttribute("data-layout-text"))
  figcaption.innerHTML = text

  figure.appendChild(imageNode.cloneNode(true))
  figure.appendChild(figcaption)

  imageNode.parentNode.replaceChild(figure, imageNode)
}

const createImageSideLayout = (imageNode, layoutType) => {
  const textContent = decodeHtml(imageNode.getAttribute("data-layout-text"))

  const container = document.createElement("div")
  container.classList.add("imageSideLayout", layoutType)

  const imageWrapper = document.createElement("div")
  imageWrapper.classList.add("imageSideLayoutWrapper")
  imageWrapper.appendChild(imageNode.cloneNode(true))

  if (layoutType === "left") {
    container.appendChild(imageWrapper)
  }

  const textElement = document.createElement("div")
  textElement.innerHTML = textContent
  textElement.classList.add("imageSideLayoutText")
  container.appendChild(textElement)

  if (layoutType === "right") {
    container.appendChild(imageWrapper)
  }

  imageNode.parentNode.replaceChild(container, imageNode)
}

export const updateImagesLayout = () => {
  const imagesWithLayout = document.querySelectorAll("img[data-layout-type]")
  imagesWithLayout.forEach(imageNode => {
    const type = imageNode.getAttribute("data-layout-type")
    if (type === IMAGE_CONTENT_TYPES.BOTTOM) {
      createFigure(imageNode)
    } else if (
      type === IMAGE_CONTENT_TYPES.RIGHT ||
      type === IMAGE_CONTENT_TYPES.LEFT
    ) {
      createImageSideLayout(imageNode, type)
    }
  })
}
