import CTAform from "../Form/CTAform"
import React from "react"
import ReactPortal from "../ReactPortal"
import CloseIcon from "../../images/CloseIcon"
import { PageContext } from "../../context/pageProvider"

const CTAPopup = ({ onClose }) => {
  const { meta } = React.useContext(PageContext)
  const { form: sanityForm } = meta

  return (
    <ReactPortal>
      <div
        onClick={onClose}
        className=" backdrop z-40 w-full h-full justify-center"
      ></div>
      <div className="CTAPopup_content p-10 bg-white z-50 shadow-2xl opacity-100 max-h-[90vh] overflow-auto h-fit max-w-lg rounded-xl top-1/2 left-1/2 fixed -translate-x-2/4 -translate-y-2/4">
        <div
          onClick={onClose}
          className="absolute w-3 h-3 top-4 right-4 overflow-hidden"
        >
          <CloseIcon />
        </div>
        <h2 className="my-5 text-2xl">Kontakta oss här</h2>
        <CTAform
          onClose={onClose}
          sanityForm={sanityForm.formFields}
          submitButtonId="button3"
        />
      </div>
    </ReactPortal>
  )
}

export default CTAPopup
