import React from "react"

function useHeadingClass() {
  const headingRef = React.useRef(null)
  const [headingClass, setHeadingClass] = React.useState("text-4xl")

  React.useEffect(() => {
    function handleResize() {
      if (headingRef.current) {
        const headingHeight = headingRef.current.clientHeight
        const headingWidth = headingRef.current.clientWidth
        const displayWidth = window.innerWidth
        const newHeadingClass =
          headingHeight > 85 || displayWidth - headingWidth < 70
            ? "text-3xl"
            : "text-4xl"
        setHeadingClass(newHeadingClass)
      }
    }

    handleResize()

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return [headingRef, headingClass]
}

export default useHeadingClass
