import React from "react"
import StandardPage from "../components/standardPage"
import ContactPage from "../components/contact/ContactPage"
import Seo from "../components/seo"
import { PageProvider } from "../context/pageProvider"
import { useLocation } from "@reach/router"

const SubPage = function ({ pageContext, location }) {
  if (pageContext.page.pageType === "contact") {
    return (
      <PageProvider data={pageContext} location={location}>
        <ContactPage />
      </PageProvider>
    )
  }
  return (
    <PageProvider data={pageContext} location={location}>
      <StandardPage />
    </PageProvider>
  )
}

export default SubPage

export const Head = ({ pageContext }) => {
  const location = useLocation()
  return (
    <PageProvider data={pageContext} location={location}>
      <Seo seo={pageContext.page.seo} />
    </PageProvider>
  )
}
