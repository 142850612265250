import * as React from "react"

import HeroSlider from "./HeroSlider"
import HeroHeading from "./HeroHeading"
import HeroWavy from "../../images/HeroWavy"

export function Slider({ cta, hero }) {
  return (
    <div
      className={`relative ${
        hero.contentPosition === "below" ? "hero" : ""
      } bg-white overflow-hidden`}
    >
      <div className="max-w-screen mx-auto">
        <div
          style={{ paddingTop: `${hero.heroPadding}` }}
          className="hero_block"
        >
          <div className="relative sm:mt-6 md:mt-8 lg:mt-10 xl:mt-14">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
            <div className="mx-auto">
              <div className="relative sm:shadow-xl sm:overflow-hidden">
                <div
                  className={`absolute inset-0 hero_bg z-0 ${
                    hero.isWavy ? "hero_bg_wavy" : ""
                  }`}
                >
                  <HeroSlider hero={hero} />
                  <div className="absolute inset-0 bg-gray-500/30 mix-blend-multiply" />
                  <HeroWavy />
                </div>

                <HeroHeading hero={hero} cta={cta} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider
