import React from "react"
import SidebarForm from "../components/Form/SidebarForm"

export const insertForm = node => {
  if (node.name && node.attribs?.id === "insertForm") {
    return (
      <div className="onlyMobile -mx-4">
        <SidebarForm componentState="formOnly" />
      </div>
    )
  }
}
