import React, { useMemo, useState } from "react"
import { render } from "react-dom"
import { PortableText } from "@portabletext/react"
import Slider from "../Slider"
import { getImageUrl } from "../../utils/getImageUrl"
import LazyLoad from "react-lazy-load"
import parse from "html-react-parser"
import { useEffect } from "react"
import { ChevronUpIcon } from "@heroicons/react/24/outline"
import SidebarForm from "../Form/SidebarForm"
import { insertForm } from "../../utils/insertForm"
import { updateImagesLayout } from "../../helpers/contentHelpers"

const regex = /<h[1-6]\b[^>]*>(.*?)<\/h[1-6]>/g

const startHeaderTag = "&lt;START_ACCORDION_HEADER&gt;"
const endHeaderTag = "&lt;END_ACCORDION_HEADER&gt;"
const startBodyTag = "&lt;START_ACCORDION_BODY&gt;"
const endBodyTag = "&lt;END_ACCORDION_BODY&gt;"

const Content = ({ typePage, content, contentData, additionalContents }) => {
  const [isForm, setIsForm] = useState(true)

  const modifiedContent = useMemo(() => {
    let result = contentData || ""
    if (additionalContents) {
      for (const item of additionalContents) {
        result += item.additionalContent || ""
      }
    }
    if (result) {
      result = result
        .replaceAll(startHeaderTag, "")
        .replaceAll(endHeaderTag, "")
        .replaceAll(startBodyTag, `<div className="accordionBody" >`)
        .replaceAll(endBodyTag, "</div>")

      if (typePage === "startpage") {
        const headers = result.match(regex)

        if (headers && headers.length >= 3) {
          result =
            result.slice(0, result.indexOf(headers[2])) +
            "<div id='insertForm'></div>" +
            result.slice(result.indexOf(headers[2]))
          setIsForm(false)
        }
      }
    }
    return result
  }, [contentData])

  // TODO: Maybe better to do that in future in React way instead of common js
  useEffect(() => {
    const allAccordionBody = document.querySelectorAll(".accordionBody")
    const images = document.querySelectorAll("img[data-mobile-width]")
    images.forEach(image => {
      const mobileWidth = image.getAttribute("data-mobile-width")
      image.style.maxWidth = mobileWidth + "px"
      image.classList.add("imageSize")
    })
    updateImagesLayout()
    allAccordionBody.forEach(accordionBody => {
      const buttonWithIcon = document.createElement("button")

      const buttonText = document.createElement("span")
      buttonText.textContent = "Läs mer"
      buttonWithIcon.appendChild(buttonText)
      buttonWithIcon.classList.add(
        "flex",
        "item-center",
        "gap-[5px]",
        "accordion",
        "font-bold",
        "pt-3"
      )
      const divWithIcon = document.createElement("div")
      divWithIcon.classList.add("chevronUpIcon")
      render(<ChevronUpIcon height="100%" />, divWithIcon)
      buttonWithIcon.appendChild(divWithIcon)
      accordionBody.insertAdjacentElement("afterend", buttonWithIcon)
      buttonWithIcon.addEventListener("click", event => {
        const currentMaxHeightValue = accordionBody.style.maxHeight
        accordionBody.style.maxHeight =
          currentMaxHeightValue === "100%" ? "0px" : "100%"
        const currentNameButtonValue = buttonText.textContent
        buttonText.textContent =
          currentNameButtonValue === "Läs mindre" ? "Läs mer " : "Läs mindre"

        const currentChevronState = divWithIcon.style.rotate
        divWithIcon.style.rotate =
          currentChevronState === "0deg" ? "180deg" : "0deg"
      })
    })
  }, [])

  return (
    <>
      <div className="elva content px-4 lg:px-0 lg:pt-2.5 pb-12 lg:pb-16">
        {(contentData || additionalContents) &&
          parse(modifiedContent, { replace: insertForm })}
        {content &&
          content.map(item => {
            return (
              <div key={item._key}>
                <h3 className="mb-2 mt-5">
                  <strong>{item.heading}</strong>
                </h3>
                <div>
                  {item._type === "textImage" && item.image && (
                    <LazyLoad>
                      <img
                        className={`w-full h-full ${
                          item.layout === "imageleft"
                            ? "md:float-left md:mx-4 md:mb-4 md:w-1/2"
                            : `${
                                item.layout === "imageright"
                                  ? "md:float-right md:mx-4 md:mb-4 md:w-1/2"
                                  : ""
                              } `
                        }`}
                        style={{ maxWidth: `${item.imageSize}px` }}
                        src={getImageUrl(item.image?.asset?._ref)}
                        alt={item.heading}
                      />
                    </LazyLoad>
                  )}
                  <PortableText value={item.content} />

                  {item._type === "logos" && (
                    <div className="flex flex-wrap justify-center gap-4 items-center">
                      {item.logos.map(image => (
                        <LazyLoad>
                          <img
                            className={`w-full h-full object-cover`}
                            style={{ maxWidth: `150px`, maxHeight: `150px` }}
                            src={getImageUrl(image?.asset?._ref)}
                          />
                        </LazyLoad>
                      ))}
                    </div>
                  )}

                  {item._type === "imageWithLink" && (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LazyLoad>
                        <img src={getImageUrl(item?.image?.asset?._ref)} />
                      </LazyLoad>
                    </a>
                  )}

                  {item._type === "imageGrid" && (
                    <Slider data={item.gridImages} />
                  )}
                </div>
                <div className="clear" />
              </div>
            )
          })}
      </div>
      {typePage === "startpage" && (
        <div className="onlyMobile">
          <SidebarForm componentState={!isForm ? "linkOnly" : ""} />
        </div>
      )}
    </>
  )
}

export default Content
