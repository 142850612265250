import React from "react"
import { useState, useEffect } from "react"
import { getImageUrl } from "../utils/getImageUrl"
import SliderModal from "./SliderModal"

const Slider = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(null)
  const [sliderData, setSliderData] = useState([])
  const [showModal, setShowModal] = useState(false)

  const nextSlide = () => {
    setCurrentSlide(
      currentSlide === sliderData.length - 1 ? 0 : currentSlide + 1
    )
  }

  const prevSlide = () => {
    setCurrentSlide(
      currentSlide === 0 ? sliderData.length - 1 : currentSlide - 1
    )
  }

  useEffect(() => {
    const sliderItems = data.map(item => {
      return {
        image: getImageUrl(item.image?.asset?._ref),
        heading: `${item.heading}`,
      }
    })
    setSliderData(sliderItems)
    setCurrentSlide(0)
  }, [])

  return (
    <div>
      {showModal && (
        <SliderModal closeModal={() => setShowModal(false)} data={data} />
      )}
      <div className="slider relative w-full">
        <button className="arrow prev" onClick={prevSlide}>
          {"<"}
        </button>
        <button className="arrow next" onClick={nextSlide}>
          {">"}
        </button>
        {sliderData.map((slide, index) => {
          return (
            <div
              onClick={() => setShowModal(true)}
              className={
                index === currentSlide
                  ? "slide h-full current absolute "
                  : "slide h-full absolute"
              }
              key={index}
            >
              {index === currentSlide && (
                <img
                  src={slide.image}
                  alt={slide.heading}
                  className="min-h-full min-w-full object-cover"
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Slider
