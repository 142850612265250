import * as React from "react"

import useHeadingClass from "../../utils/useHeadingClass"
import { CallToAction } from "../callToAction"
import { headingSplitter, blockToContent } from "../../helpers"

const HeroHeading = ({ hero, cta }) => {
  const [headingRef, headingClass] = useHeadingClass()

  const heroContentPosition = React.useMemo(() => {
    return hero.headerPosition === "end"
      ? "right"
      : hero.headerPosition === "start"
      ? "left"
      : "center"
  }, [])

  return (
    <div
      className={`relative px-9 hero_content py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 elva flex flex-col z-10 items_${
        hero.headerPosition || "center"
      }`}
    >
      <h1
        ref={headingRef}
        className={`flex-wrap flex justify-center text_shadow gap-x-3 gap-y-1 font-extrabold ${headingClass} text-white tracking-tight sm:text-5xl lg:text-6xl text-${
          heroContentPosition || "center"
        }`}
      >
        {headingSplitter(hero?.heading)}
      </h1>
      {hero?.subheading && (
        <p
          className={`mt-6 max-w-lg text-lg font-bold text_shadow text-white sm:max-w-3xl text-center sm:text-${
            heroContentPosition || "center"
          }`}
        >
          {blockToContent(hero.subheading[0]?.children)}
        </p>
      )}
      <div className="mt-6 md:mt-10 max-w-sm sm:max-w-none sm:flex hero_button sm:justify-center">
        <CallToAction {...cta} hero={hero} />
      </div>
    </div>
  )
}

export default HeroHeading
