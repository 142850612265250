import * as React from "react"
import { getImageUrl } from "../../utils/getImageUrl"
import HeroHeading from "./HeroHeading"

export function Card({ cta, hero }) {
  const bgUrl = hero.bgImage
    ? hero.bgImage
    : getImageUrl(hero.bgimage?.asset?._ref)
  return (
    <>
      <div
        className={`relative ${
          hero.contentPosition === "below" ? "hero" : ""
        } bg-white overflow-hidden`}
      >
        <div className="max-w-[1300px] mx-auto">
          <div
            style={{ paddingTop: `${hero.heroPadding}` }}
            className="sm:px-4 hero_block"
          >
            <div className="relative sm:mt-6 md:mt-8 lg:mt-10 xl:mt-14">
              <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
              <div className="max-w-8xl mx-auto">
                <div className="relative sm:shadow-xl sm:rounded-md sm:overflow-hidden">
                  <div
                    className="absolute inset-0 hero_bg"
                    style={{
                      backgroundImage: `url(${bgUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="absolute inset-0 bg-gray-500/30 mix-blend-multiply" />
                  </div>

                  <HeroHeading hero={hero} cta={cta} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Card
