import React from "react"
import { GoogleMap } from "../maps"

const ContactMap = ({ hero }) => {
  if (hero.location?._type === "geopoint") {
    return <GoogleMap hero={hero} />
  } else {
    return
  }
}
export default ContactMap
