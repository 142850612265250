import * as React from "react"
import { Link } from "gatsby"
import CTAPopup from "./CTABlock/CTAPopup"
import primaryButtonVariants from "../constants/primaryButtonVariants"

// default styles classes primary
const linkClassDefault =
  "w-fit min-h-[40px] sm:min-h-[50px] min-w-[170px] sm:min-w-[250px] flex flex-nowrap items-center btn justify-center border border-transparent text-base font-medium rounded-md text-white primaryBg md:py-4 md:text-lg md:px-10"
const linkButtonClassDefault = "px-5 py-2 w-full h-full"
const soloButtonClassDefault =
  "w-fit min-h-[40px] sm:min-h-[50px] min-w-[170px] sm:min-w-[250px] whitespace-nowrap m-0 flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white primaryBg md:py-3 md:text-lg md:px-10"

export function CallToAction({ primaryButton, secondaryButton, hero }) {
  const [isShown, setIsShown] = React.useState(false)
  const { linkClass, linkButtonClass, soloButtonClass } = React.useMemo(() => {
    const output = {
      linkClass: linkClassDefault,
      linkButtonClass: linkButtonClassDefault,
      soloButtonClass: soloButtonClassDefault,
    }
    if (
      primaryButton?.buttonversion &&
      primaryButton.buttonversion === primaryButtonVariants.v2
    ) {
      output.soloButtonClass += " border-white primaryBgOpacity "
    }

    return output
  }, [primaryButton])

  return (
    <>
      {isShown && (
        <CTAPopup
          onClose={e => {
            setIsShown(false)
          }}
        />
      )}
      <div className=" sm:flex sm:justify-center lg:justify-start">
        {primaryButton?.text && primaryButton?.link && (
          <div className="rounded-md w-full flex justify-center">
            {!hero.kontaktToPopup ? (
              <Link to={primaryButton.link} className={linkClass}>
                <button id="button6" className={linkButtonClass}>
                  {primaryButton.text}
                </button>
              </Link>
            ) : (
              <button
                id="button2"
                onClick={() => setIsShown(true)}
                className={soloButtonClass}
              >
                {primaryButton.text}
              </button>
            )}
          </div>
        )}
        {secondaryButton?.text && secondaryButton?.link && (
          <div className="w-full mt-3 rounded-md sm:mt-0 sm:ml-3 flex justify-center">
            <a
              href={secondaryButton?.link.replaceAll(" ", "")}
              className="w-fit min-w-52 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white md:text-lg secondaryBg md:px-10"
            >
              <div className="whitespace-nowrap m-0">
                {secondaryButton?.text}
              </div>
            </a>
          </div>
        )}
      </div>
    </>
  )
}
