import * as React from "react"

export const headingSplitter = heading =>
  heading?.split(" ").map((word, index) => (
    <span
      key={`span-${index}`}
      className={`${heading.length < 10 && "block"} xl:inline ${
        index >= 1 && "secondaryColor"
      }`}
    >
      {word}{" "}
    </span>
  ))

export const blockToContent = blocks => blocks?.map(({ text }) => text)
