import { PortableText } from "@portabletext/react"
import React from "react"
import { getImageUrl } from "../../utils/getImageUrl"
import LazyLoad from "react-lazy-load"

const ContentContactPage = ({ content }) => {
  return (
    <div className="w-full content p-4 pt-9">
      {content &&
        content.map(item => {
          console.log(item)
          if (item._type === "imageWithLink") {
            console.log(item.link)
            return (
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <LazyLoad>
                  <img src={getImageUrl(item?.image?.asset?._ref)} />
                </LazyLoad>
              </a>
            )
          }
          return <PortableText value={item.content} />
        })}
    </div>
  )
}

export default ContentContactPage
