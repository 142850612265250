import React from "react"
import CTAform from "./CTAform"
import { PortableText } from "@portabletext/react"
import { PageContext } from "../../context/pageProvider"
const SidebarForm = ({ componentState }) => {
  const pageContext = React.useContext(PageContext)
  const {
    form: sanityForm,
    sidebarInternalLinks: sanitySidebarInternalLinks,
    translations,
  } = pageContext.meta
  if (!sanityForm) {
    return null
  }
  return (
    <div
      className={`sidebar-desc ${componentState === "formOnly" ? "py-4" : ""}`}
    >
      <div
        className={`sidebar p-2.5 ${
          componentState === "linkOnly" ? "hiddenMobile" : ""
        }`}
      >
        <h5 className="mt-5 mb-2.5 text-3xl font-bold">
          {translations?.kontakta_oss || "Kontakta oss"}
        </h5>
        <CTAform sanityForm={sanityForm.formFields} submitButtonId="button10" />
      </div>
      <div
        className={`pb-9 ${
          componentState === "formOnly" ? "hiddenMobile" : ""
        }`}
      >
        {sanitySidebarInternalLinks?.sidebarInterLinks?.map(item => {
          return (
            <div key={item.title} className="sidebar-title p-4">
              <h2 className="text-2xl mt-5 mb-2">
                <a href={item.url}>{item.title}</a>
              </h2>
              <PortableText value={item.text} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SidebarForm
