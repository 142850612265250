import React from "react"
import { useState, useEffect } from "react"
import { XMarkIcon as XIcon } from "@heroicons/react/24/outline"
import { getImageUrl } from "../utils/getImageUrl"

const SliderModal = ({ data, closeModal }) => {
  const [currentSlide, setCurrentSlide] = useState(null)
  const [sliderData, setSliderData] = useState([])

  const nextSlide = () => {
    setCurrentSlide(
      currentSlide === sliderData.length - 1 ? 0 : currentSlide + 1
    )
  }

  const prevSlide = () => {
    setCurrentSlide(
      currentSlide === 0 ? sliderData.length - 1 : currentSlide - 1
    )
  }

  useEffect(() => {
    const sliderItems = data.map(item => {
      return {
        image: `${getImageUrl(item.image?.asset?._ref)}`,
        heading: `${item.heading}`,
      }
    })
    setSliderData(sliderItems)
    setCurrentSlide(0)
  }, [data])

  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-40 flex justify-center items-center ">
      <div
        className="absolute w-full h-full bg-black opacity-60"
        onClick={closeModal}
      />
      {sliderData.map((slide, index) => {
        return (
          <div
            className={
              index === currentSlide
                ? "slide  current relative mx-3.5 max-w-[90%] max-h-[90%]"
                : "slide absolute mx-3.5 max-w-[90%] max-h-[90%]"
            }
            key={index}
          >
            <button type="button" className="closeIcon" onClick={closeModal}>
              <XIcon />
            </button>
            <button className="arrow prev" onClick={prevSlide}>
              {"<"}
            </button>
            <button className="arrow next" onClick={nextSlide}>
              {">"}
            </button>
            {index === currentSlide && (
              <img
                src={slide.image}
                alt={slide.heading}
                className="min-h-full min-w-full"
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default SliderModal
