import React from "react"

const Button = ({ value, type, buttonId }) => {
  return (
    <button
      id={buttonId}
      className="button font-semibold px-9 py-4 leading-none rounded m-auto"
      type={type}
    >
      {value}
    </button>
  )
}

export default Button
