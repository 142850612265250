import * as React from "react"
import { getImageUrl } from "../../utils/getImageUrl"
import HeroHeading from "./HeroHeading"
import HeroWavy from "../../images/HeroWavy"

export function FullScreen({ cta, hero }) {
  const bgUrl = hero.bgImage
    ? hero.bgImage
    : getImageUrl(hero.bgimage?.asset?._ref)

  const altText = hero.bgImage ? hero.altText : hero.bgimage?.alttext

  return (
    <>
      <div
        className={`relative ${
          hero.contentPosition === "below" ? "hero" : ""
        } bg-white overflow-hidden`}
      >
        <div className="max-w-screen mx-auto">
          <div
            style={{ paddingTop: `${hero.heroPadding}` }}
            className="hero_block"
          >
            <div className="relative sm:mt-6 md:mt-8 lg:mt-10 xl:mt-14">
              <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
              <div className="mx-auto">
                <div className="relative sm:shadow-xl sm:overflow-hidden">
                  <div
                    className={`absolute inset-0 hero_bg ${
                      hero.isWavy ? "hero_bg_wavy" : ""
                    }`}
                  >
                    {(hero.bgImage || hero.bgimage?.asset) && (
                      <img
                        src={bgUrl}
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        alt={altText || ""}
                      />
                    )}
                    <div className="absolute inset-0 bg-gray-500/30 mix-blend-multiply" />
                    <HeroWavy />
                  </div>

                  <HeroHeading hero={hero} cta={cta} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FullScreen
