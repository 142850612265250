import React from "react"
import { PageContext } from "../../context/pageProvider"
import CookiesBanner from "../CookiesBanner"
import Footer from "../Footer/Footer"
import HeroPicker from "../hero/heroPicker"
import Navigation from "../navigation"
import ContactForm from "./ContactForm"
import ContactMap from "./ContactMap"
import ContentContactPage from "./ContentContactPage"

const ContactPage = () => {
  const { meta, page } = React.useContext(PageContext)
  const { hero, cta, content } = page
  const { callInHeader } = meta.settings

  return (
    <>
      <CookiesBanner />
      <Navigation />
      <div className={`${callInHeader ? "pt-28" : "pt-16"} md:pt-20 xl:pt-18`}>
        <HeroPicker hero={hero} cta={cta} />
      </div>
      <div className=" elva flex flex-col-reverse md:flex-row justify-between">
        <ContentContactPage content={content} />
        <ContactForm content={content} />
      </div>
      <ContactMap hero={hero} />
      <Footer />
    </>
  )
}

export default ContactPage
